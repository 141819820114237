import router from "@/router"
import { computed, ref, unref } from "@vue/composition-api"
import { defineStore } from "pinia"
import { clearTokenFromLocalStorage } from "../composables/use-fetch"
import { useFetch } from "../composables/use-fetch"
import { useQuery } from "../composables/use-query"

export const useAuthStore = defineStore("auth", () => {
  const userDisplayName = ref("-")
  const role = computed(() => me.value?.role)
  const isUser = computed(() => role.value?.slug == "user")
  const isSuperAdmin = computed(() => role.value?.slug == "super-admin")
  const isAdmin = computed(() => role.value?.slug == "admin")
  const isCommon = computed(() => role.value?.slug == "common")
  const tempMe = ref<any>(null)

  const TOKEN_NAME = process.env.VUE_APP_TOKEN_NAME ?? "eAulaId"
  const token = localStorage.getItem(TOKEN_NAME)

  const getMe = () => {
    const handleError = (err: any) => {
      const statusCode = err.status as number
      const isUserNotFound = statusCode === 404
      const isUnathorized = statusCode === 401 || err.message == "Unauthorized"
      const forceSignOut = isUserNotFound || isUnathorized
      // console.log("forceSignOut:", forceSignOut)

      if (forceSignOut) signOut()
    }

    return (
      useFetch("/www/me", { onError: (err: any) => handleError(err) })
        // .then((res) => res.data)
        .catch((err: any) => handleError(err))
    )
  }

  const clearUser = () => {
    userDisplayName.value = "-"
    clearTokenFromLocalStorage()
    me.value = null
  }

  const signOut = async () => {
    const currentRoute = router.currentRoute
    const redirectToAuthPage = currentRoute.fullPath.includes("/app")

    if (redirectToAuthPage) router.push("/masuk")

    // await sleep(400)
    clearUser()
  }
  const {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    keyedData: me,
    isLoading,
    error,
    fetch,
  } = useQuery(["me"], getMe, {
    dataKey: "user",
    fetchOnInit: !!token,
    onSuccess: () => {
      userDisplayName.value = getUserDisplayName(me.value)
      tempMe.value = me.value
    },
  })

  const getUserDisplayName = (user: any) => {
    const _user = unref(user)
    if (!_user) return "-"
    const username = _user.username
    const displayName = _user.profile?.displayName

    return displayName ?? username
  }

  const schedulePack = computed(() => {
    return me.value?.schedulePack
  })

  const schedule = computed(() => {
    const schedulePack = me.value?.schedulePack
    const currentDate = new Date()
    const dayIndex = currentDate.getDay()
    const schedules = schedulePack?.schedules ?? []

    return schedules.find((s: any) => s.dayIndex === dayIndex)
  })

  const locations = computed<any[]>(() => me.value?.locations ?? [])
  const attendance = ref<any>(null)
  const getAttendance = async () => {
    isLoading.value = true
    try {
      const userId = me.value.id
      const currenDate = new Date()
      currenDate.setHours(currenDate.getHours() + 8)
      const date = currenDate.toISOString().slice(0, 10)
      const res = await useFetch(`/attendances?userId=${userId}&date=${date}`)
      if (res.result?.length) attendance.value = res.result[0]
    } catch (err) {
      //
    } finally {
      isLoading.value = false
    }
  }

  const isLinkedWithBapintar = computed(() => !!me.value?.bapintarSsoId)

  return {
    me,
    role,
    isUser,
    isSuperAdmin,
    isAdmin,
    isCommon,
    error,
    isLoading,
    getMe,
    userDisplayName,
    getUserDisplayName,
    fetch,
    signOut,
    schedule,
    schedulePack,
    locations,
    attendance,
    getAttendance,
    isLinkedWithBapintar,
  }
})
