import { ComputedRef, ref, unref } from "@vue/composition-api"
import { MaybeRef } from "@vueuse/core"
import { getApiBaseUrl } from "../helpers/get-api-base-url"
import { toFormData } from "../helpers/form-data"

type FetchOptions = any
// type FetchOptions = Parameters<typeof fetch>[1] &
//   Record<string, any> & {
//     body: any
//   }

export const TOKEN_NAME = process.env.VUE_APP_TOKEN_NAME ?? "presensiNonAsnId"

export const getTokenFromLocalStorage = () => localStorage.getItem(TOKEN_NAME)

export const setTokenToLocalStorage = (token: string) => {
  if (token) localStorage.setItem(TOKEN_NAME, token)
}

export const clearTokenFromLocalStorage = () =>
  localStorage.removeItem(TOKEN_NAME)

export const useFetch = (
  url: MaybeRef<string> | ComputedRef<string>,
  options?: FetchOptions
) => {
  const _options = unref(options) ?? ({} as FetchOptions)
  if (_options?.body) _options.body = toFormData(unref(_options.body as any))

  const {
    beforeFetch = () => null,
    afterFetch = () => null,
    onSuccess = async () => null,
    onError = async () => null,
  } = _options

  const isLoading = ref(false)

  const _url = unref(url)

  const baseURL = getApiBaseUrl()
  const fullUrl = baseURL + _url

  const token = localStorage.getItem(TOKEN_NAME)

  if (token)
    _options.headers = {
      authorization: "Bearer " + token,
    }

  isLoading.value = true
  beforeFetch?.()
  let res: any
  return fetch(fullUrl, _options)
    .then(async (response) => {
      res = response

      if (!response.ok) throw Error(response.statusText)

      const data = await response.clone().json()
      await onSuccess?.(data)
      return data
    })
    .catch(async () => {
      if (res?.json) res = await res.json()
      console.error("error:", res)

      await onError?.(res)

      return res
    })
    .finally(() => {
      isLoading.value = false
      afterFetch?.()
    })
}
