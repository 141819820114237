import { render, staticRenderFns } from "./App.vue?vue&type=template&id=75030346&"
import script from "./App.vue?vue&type=script&lang=ts&"
export * from "./App.vue?vue&type=script&lang=ts&"
import style0 from "./App.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.9.8_css-loader@6.7.1_vue-template-compiler@2.6.14_webpack@5.73.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/.pnpm/vuetify-loader@1.8.0_vue-template-compiler@2.6.14_vuetify@2.6.6_webpack@5.73.0/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VApp})
