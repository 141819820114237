type FormDataPrimitive = string | Blob | number | boolean

interface FormDataNest {
  [x: string]: FormVal
}

type FormVal = FormDataNest | FormDataPrimitive

function isValidDate(date: any) {
  return (
    date &&
    Object.prototype.toString.call(date) === "[object Date]" &&
    !isNaN(date)
  )
}

const buildFormData = (
  formData: FormData,
  data: FormVal,
  parentKey?: string
) => {
  if (Array.isArray(data)) {
    data.forEach((el) => {
      buildFormData(formData, el, parentKey)
    })
  } else if (typeof data === "object" && !(data instanceof File) && !!data) {
    Object.keys(data).forEach((key) => {
      let value = (data as FormDataNest)[key]
      if (isValidDate(value)) value = (value as unknown as Date).toISOString()
      buildFormData(formData, value, parentKey ? `${parentKey}.${key}` : key)
    })
  } else {
    // if (Object.keys(data).length === 0) {
    //   return
    // }

    let value = data
    if (typeof data === "boolean" || typeof data === "number")
      value = data.toString()
    if (isValidDate(data)) value = (data as unknown as Date).toISOString()

    formData.append(parentKey as string, value as string)
  }
}

export const toFormData = (data: Record<string, FormDataNest>) => {
  const formData = new FormData()

  buildFormData(formData, data)

  return formData
}
