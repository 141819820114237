import { Route } from "vue-router"
import { useUiStore } from "../stores/ui-store"

export const updateBreadcrumb = (to: Route) => {
  const arrayPath: any[] = to.fullPath.split("/")
  const breadcrumbs: any[] = []
  arrayPath.map((path, index) => {
    if (path) {
      const to = `${arrayPath.slice(0, index + 1).join("/")}`
      const text = path === "app" ? "UTAMA" : String(path).toUpperCase()
      const disabled = arrayPath.length - 1 === index

      breadcrumbs.push({
        to,
        text,
        disabled,
        color: disabled ? "black" : "primary",
      })
    }
  })

  const uiStore = useUiStore()
  uiStore.setBreadcrumbs(breadcrumbs)
}
