export const getApiBaseUrl = () => {
  const protocol = window.location.protocol + "//"
  const hostname = window.location.hostname
  const port = 3001
  const developmentUrl = `${hostname}:${port}`
  const productionUrl = process.env.VUE_APP_API_BASE_URL ?? developmentUrl
  const isDevelopment = process.env.NODE_ENV === "development"
  const url = !isDevelopment ? productionUrl : developmentUrl
  const baseUrl = `${protocol}${url}`

  return baseUrl
}
